import styled from 'styled-components';
import { colors, transitions, breakpoints } from 'variables';

export const StyledFooter = styled.footer`
   margin: 60px 0 50px;
   display: flex;
   width: 100%;
   flex-wrap: wrap;
   color: ${colors.black};

   > * {
      flex: 100%;
   }

   hr {
      margin-top: 60px;
      border: none;
      height: 1px;
      background-color: ${colors.greyMap.get(400)};
   }

   .poirLogo {
     width: 100%;
     margin-top: 60px;
   }

   .poirDescription {
     text-align: center;
     margin-top: 20px;
     font-size: 16px;
   }

   ${breakpoints.lg`
      text-align: left;
      margin: 100px 0 70px;
      padding: 0 70px;
   `}
`;

export const Contact = styled.div`
   flex: 100%;
   margin-bottom: 50px;

   ${breakpoints.lg`
      flex: 35%;
      margin-bottom: 0;

      a {
         margin-top: 20px;
      }
   `}
`;
export const FooterListWrapper = styled.div`
   flex-basis: 45%;
   margin-right: 10px;
   margin-top: 30px;
   ${breakpoints.md`
      margin-right: 0px;
      margin-top: 0;
      flex: 20%;
   `};
`;

export const SectionHeader = styled.h4`
   font-weight: 600;
   margin: 0;
   margin-bottom: 25px;
   line-height: 36px;
`;

export const BigSectionHeader = styled.h3`
   margin-bottom: 15px;
   font-weight: 600;

   ${breakpoints.lg`
      margin-bottom: initial;
   `}
`;

export const List = styled.ul`
   li {
      font-weight: 400;
      font-size: 1.5rem;
      padding: 12px 0;

      a {
         transition: color ${transitions.easeInOut};

         &:hover {
            color: ${colors.brand};
         }
      }
   }
   .socials {
      img {
         margin-right: 10px;
      }
   }
   ${breakpoints.md`
      li { padding: 8px 0; }
   `};
`;

export const CreditsContainer = styled.div`
   margin-top: 30px;
   display: flex;
   flex-direction: column;

   ${breakpoints.md`
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
   `}
`;

export const LogoContainer = styled.div`
   display: flex;
`;

export const Logo = styled.img`
   max-width: 120px;
`;

export const BrandName = styled.div`
   font-size: 1.92rem;
   font-weight: 300;

   span {
      font-weight: 600;
   }
`;

export const CreatorsContainer = styled.div`
   font-size: 1.4rem;
   color: ${colors.black};
   letter-spacing: 0.55px;
   margin-top: 25px;

   a {
      font-weight: 600;

      &:hover {
         color: ${colors.brand};
      }
   }

   .greyLink {
      cursor: pointer;
      background: none;
      border: none;
      position: relative;
      color: ${colors.greyMap.get(500)};
      span {
         color: ${colors.greyMap.get(500)};
         font-weight: 600;
      }
   }

   span {
      display: block;

      &:nth-of-type(2) {
         margin: 25px 0;
      }
   }

   ${breakpoints.md`
      margin-top: 0;
      align-self: flex-end;

      span {
         display: inline-block;

         &:nth-of-type(2) {
            margin: 0;
         }
      }
   `}
`;

import { css } from 'styled-components';

export const colors = {
   white: '#ffffff',
   black: '#000000',
   blue: '#295BFC',
   red: '#FB334B',
   lightBlue: '#D8EAFA',
   lightGreen: '#E2F8FF',
   lightViolet: '#F0F4FF',
   trialBg: '#D9F6FF',

   greyMap: new Map([
      [100, '#F6F7FA'],
      [200, '#E8ECF9'],
      [300, '#DBE1F2'],
      [400, '#C4CCE7'],
      [500, '#868DA5'],
   ]),
};
colors.brand = colors.blue;

export const fonts = {
   primary: "'Metropolis', sans-serif",
};

export const transitions = {
   easeInOut: '.15s ease-in-out',
   easeInOutSlow: '.5s cubic-bezier(0.5, 0, 0.5, 1)',
   ease: '.5s ease',
};

export const breakpointsList = {
   xs: '375px',
   sm: '576px',
   md: '768px',
   lg: '992px',
   xl: '1200px',
};

export const breakpoints = Object.keys(breakpointsList).reduce(
   (accumulator, label) => {
      accumulator[label] = (...args) => css`
         @media (min-width: ${breakpointsList[label]}) {
            ${css(...args)};
         }
      `;
      return accumulator;
   },
   {},
);

const theme = {
   colors: colors,
   transitions: transitions,
   fonts: fonts,
};

export default theme;

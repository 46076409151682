import React, { forwardRef } from 'react';
import {
   LinkAnchor,
   LinkList,
   StocksTooltipWrapper,
} from './StocksTooltip.styled';

const StockTooltip = forwardRef((_, ref) => {
   const stockLinks = [
      {
         author: 'aleksandarlittlewolf',
         url:
            'https://www.freepik.com/free-photo/factory-workers-working-together-industrial-production-hall_11035628.htm',
      },
      {
         author: 'freepik',
         url:
            'https://www.freepik.com/free-photo/female-researcher-laboratory-with-test-tubes-safety-glasses_11374671.htm',
      },
      {
         author: 'karlyukav',
         url:
            'https://www.freepik.com/free-photo/vertical-image-happy-experienced-elderly-male-builder-with-gray-beard-posing-blank-wall-wearing-glasses-rubber-gloves-hat-belt-with-instruments-looking-with-broad-smile_11162490.htm',
      },
      {
         author: 'senivpetro',
         url:
            'https://www.freepik.com/free-photo/middle-aged-business-man-hard-hat_10025061.htm',
      },
      {
         author: 'freepik',
         url:
            'https://www.freepik.com/free-photo/female-researcher-biotechnology-laboratory-with-tablet_11374992.htm',
      },
      {
         author: 'RAEng_Publications',
         url:
            'https://pixabay.com/photos/engineer-engineering-chemistry-4915445/',
      },
      {
         author: 'senivpetro',
         url:
            'https://www.freepik.com/free-photo/woman-tailor-working-sewing-factory_5495091.htm',
      },
      {
         author: 'Racool_studio',
         url:
            'https://www.freepik.com/free-photo/woman-working-lab-with-microscope_10094732.htm',
      },
      {
         author: 'aleksandarlittlewolf',
         url:
            'https://www.freepik.com/free-photo/manager-supervisor-worker-discussing-about-production-results-new-strategy-factory-industrial-hall_11034000.htm',
      },
      {
         author: 'ThisIsEngineering',
         url:
            'https://www.pexels.com/pl-pl/zdjecie/kobieta-portret-profesjonalny-badania-3861445/',
      },
      {
         author: 'freepik',
         url:
            'https://www.freepik.com/free-photo/medium-shot-woman-holding-flask_8810880.htm',
      },
      {
         author: 'Racool_studio',
         url:
            'https://www.freepik.com/free-photo/woman-working-lab-with-microscope_10094750.htm#page=12&query=laboratory&position=46',
      },
      {
         author: 'senivpetro',
         url:
            'https://www.freepik.com/free-photo/handsome-business-man-engineer-hard-hat-building_7376145.htm',
      },
      {
         author: 'standret',
         url:
            'https://www.freepik.com/free-photo/portrait-young-worker-hard-hat-large-metalworking-plant_10519377.htm#page=2&query=production&position=22',
      },
      {
         author: 'master1305',
         url:
            'https://www.freepik.com/free-photo/builder-construction-vest-orange-helmet-with-tablet_11453649.htm',
      },
      {
         author: 'mega-studio',
         url:
            'https://www.freepik.com/free-photo/portrait-professional-architect-woman-wearing-yellow-helmet-standing-outdoors-engineer-architect-concept_10449217.htm',
      },
      {
         author: 'senivpetro',
         url:
            'https://www.freepik.com/free-photo/middle-aged-business-man-hard-hat_10025071.htm',
      },
   ];
   return (
      <StocksTooltipWrapper ref={ref}>
         <LinkList>
            {stockLinks.map((link, idx) => (
               <li key={idx}>
                  <LinkAnchor target="_blank" rel="noreferrer" href={link.url}>
                     {link.author}
                  </LinkAnchor>
               </li>
            ))}
         </LinkList>
      </StocksTooltipWrapper>
   );
});

export default StockTooltip;

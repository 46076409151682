import { useCallback, useState } from 'react';

import { useDidMount } from './useDidMount';

export function useLocalStorage(key, defaultValue) {
   const [value, setValue] = useState(null);

   useDidMount(() => {
      try {
         const savedValue = localStorage.getItem(key);
         setValue(savedValue || defaultValue);
      } catch {
         setValue(defaultValue);
      }
   });

   const setStoredValue = useCallback(
      (val) => {
         setValue(val);
         try {
            localStorage.setItem(key, val);
         } catch (err) {
            console.error(err);
         }
      },
      [key],
   );

   return [value, setStoredValue];
}

import React from 'react';

const Analytics = () => {
   return (
      <noscript>
         <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-W67FQGC"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
         ></iframe>
      </noscript>
   );
};

export default Analytics;

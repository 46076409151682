import React from 'react';
import { Helmet } from 'react-helmet';
import NavbarContextProvider from 'contexts/NavbarContext';
import Layout from 'components/Layout';
import 'what-input';
import GlobalStyles from 'src/styles/globalStyles';
import smartlook from 'src/utilities/smartlook';
import analytics from 'src/utilities/analytics';
import Analytics from 'components/Layout/Analytics';

export const onRenderBody = ({ setPreBodyComponents }) => {
   setPreBodyComponents([<Analytics />]);
};

export const onClientEntry = async () => {
   if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
   }
};

export const wrapPageElement = ({ element }) => (
   <NavbarContextProvider>
      <GlobalStyles />
      <Helmet>
         <html lang="pl" />
         <link rel="preconnect" href="https://fonts.gstatic.com" />
         <script>{smartlook}</script>
         <script>{analytics}</script>
         <link
            href="https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap"
            rel="stylesheet"
         />
         <title>Lab Control</title>
         <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Layout>{element}</Layout>
   </NavbarContextProvider>
);

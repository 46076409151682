import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { globalHistory as history } from '@reach/router';
import logo from 'assets/logo_color.svg';
import eulogo from 'assets/images/eulogo.png';
import Button from 'components/shared/Button';
import { useNavbarContext } from 'contexts/NavbarContext';
import routes from 'data/globals/routes';
import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import scrollTo from 'utilities/scrollTo';
import Hamburger from './Hamburger';
import {
   DarkOverlay,
   ListContainer,
   ListItemWithList,
   MenuContainer,
   NavContainer,
   NavList,
   NavWrapper,
} from './Navbar.styled';

const Navbar = () => {
   const [solutionsVisible, setSolutionsVisible] = useState(false);
   const [functionalitiesVisible, setFunctionalitiesVisible] = useState(false);
   const [isMenuOpen, setMenuOpen] = useState(false);
   const { location } = history;
   const { isMenuVisible, hideMenu, showMenu } = useNavbarContext();
   const navRef = useRef(null);
   const isFirstRender = useRef(true);
   const closeMenu = () => setMenuOpen(false);

   let prevShouldNavbarHide = null;

   useScrollPosition(
      ({ prevPos, currPos }) => {
         if (prevPos.y === currPos.y) return;

         const prevY = Math.abs(prevPos.y);
         const currY = Math.abs(currPos.y);
         const navHeight = navRef.current.getBoundingClientRect().height;
         const shouldNavbarHide = currY > prevY && currY > navHeight;

         if (currY < navHeight) navRef.current.classList.remove('scroll-up');

         if (prevShouldNavbarHide === shouldNavbarHide) return;

         shouldNavbarHide ? hideMenu(-navHeight) : showMenu(navHeight, 0);

         prevShouldNavbarHide = shouldNavbarHide;
      },
      [],
      null,
      false,
      100,
   );

   useEffect(() => {
      if (isFirstRender.current) {
         isFirstRender.current = false;
         return;
      }

      const nav = navRef.current;
      if (isMenuVisible) {
         nav.classList.remove('scroll-down');
         nav.classList.add('scroll-up');
      } else {
         nav.classList.remove('scroll-up');
         nav.classList.add('scroll-down');
      }
   }, [isMenuVisible]);

   useEffect(() => {
      const overflowStatus = isMenuOpen ? 'hidden' : 'visible';
      document.body.style.overflow = overflowStatus;
   }, [isMenuOpen]);

   const handleClickLink = (sectionId) => {
      location.pathname === routes.functionalities && scrollTo(sectionId);
      closeMenu();
   };

   return (
      <NavContainer fullwidth ref={navRef}>
         <NavWrapper as="nav">
            <Link to={routes.home}>
               <img alt="logo" src={logo} height="30px" />
            </Link>
            <Hamburger isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
            {isMenuOpen && (
               <DarkOverlay onClick={closeMenu} isMenuOpen={isMenuOpen} />
            )}
            <MenuContainer isMenuOpen={isMenuOpen}>
               <ListContainer>
                  <NavList>
                     <ListItemWithList arrowUp={!solutionsVisible}>
                        <button
                           onClick={() => setSolutionsVisible((prev) => !prev)}
                           className={`
                                         with-arrow ${
                                            location.pathname.match(
                                               /solutions/,
                                            ) && 'active-nav-link'
                                         } `}
                        >
                           Rozwiązania
                        </button>
                        <ul
                           className={`nested-list ${
                              !solutionsVisible && 'invisible-mobile'
                           }`}
                        >
                           <li>
                              <Link
                                 onClick={closeMenu}
                                 activeClassName="active-nav-link"
                                 to={routes.production}
                              >
                                 Dla firm produkcyjnych
                              </Link>
                           </li>

                           <li>
                              <Link
                                 onClick={closeMenu}
                                 activeClassName="active-nav-link"
                                 to={routes.laboratory}
                              >
                                 Dla laboratoriów
                              </Link>
                           </li>

                           <li>
                              <Link
                                 onClick={closeMenu}
                                 activeClassName="active-nav-link"
                                 to={routes.construction}
                              >
                                 Dla firm budowlanych
                              </Link>
                           </li>
                        </ul>
                     </ListItemWithList>

                     <ListItemWithList arrowUp={!functionalitiesVisible}>
                        <button
                           onClick={() =>
                              setFunctionalitiesVisible((prev) => !prev)
                           }
                           className={`
                                        with-arrow ${
                                           location.pathname ===
                                              routes.functionalities &&
                                           'active-nav-link'
                                        } `}
                        >
                           Funkcjonalności
                        </button>
                        <ul
                           className={`nested-list ${
                              !functionalitiesVisible && 'invisible-mobile'
                           }`}
                        >
                           <li>
                              <Link
                                 to={routes.functionalities}
                                 state={{
                                    sectionId: 'functionalities-reception',
                                 }}
                                 onClick={() =>
                                    handleClickLink('functionalities-reception')
                                 }
                              >
                                 Planowanie
                              </Link>
                           </li>

                           <li>
                              <Link
                                 to={routes.functionalities}
                                 state={{
                                    sectionId: 'functionalities-operations',
                                 }}
                                 onClick={() =>
                                    handleClickLink(
                                       'functionalities-operations',
                                    )
                                 }
                              >
                                 Operacje
                              </Link>
                           </li>

                           <li>
                              <Link
                                 to={routes.functionalities}
                                 state={{
                                    sectionId: 'functionalities-reports',
                                 }}
                                 onClick={() =>
                                    handleClickLink('functionalities-reports')
                                 }
                              >
                                 Raportowanie
                              </Link>
                           </li>
                        </ul>
                     </ListItemWithList>

                     <li>
                        <Link
                           to={routes.benefits}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Korzyści
                        </Link>
                     </li>
                     <li>
                        <Link
                           to={routes.pricing}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Cennik
                        </Link>
                     </li>
                     <li className="cta">
                        <Button
                           small
                           filled
                           link={routes.contact}
                           onClick={closeMenu}
                        >
                           Wypróbuj
                        </Button>
                     </li>
                     <li>
                        <Link to={routes.eu}><img alt="eulogo" src={eulogo} className="euLogo" height="45px" /></Link>
                     </li>
                  </NavList>
               </ListContainer>
            </MenuContainer>
         </NavWrapper>
      </NavContainer>
   );
};
export default Navbar;

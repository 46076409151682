import React from 'react';
import { HamburgerContainer, IconContainer } from './Hamburger.styled';

const Hamburger = ({ isMenuOpen, setMenuOpen }) => {
   const handleToggleMenu = () => {
      setMenuOpen(prevState => !prevState);
   }

   return (
      <HamburgerContainer onClick={handleToggleMenu}>
         {isMenuOpen ? "Zamknij" : "Menu"}

         <IconContainer isMenuOpen={isMenuOpen}>
            <div></div>
            <div></div>
            <div></div>
         </IconContainer>
      </HamburgerContainer>
   );
}

export default Hamburger;

const scrollTo = (element, topOffset = 0) => {
   const anchor = document.getElementById(element);
   if (anchor) {
      const anchorOffset = anchor.offsetTop - topOffset;

      window.scrollTo({
         top: anchorOffset,
         behavior: 'smooth',
      });
   }
};

export default scrollTo;

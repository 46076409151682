import styled from 'styled-components';
import { breakpoints, colors } from 'styles/variables';

export const StocksTooltipWrapper = styled.div`
   background-color: white;
   overflow-y: auto;
   padding: 20px 23px;
   background-color: #ffffff;
   border: 1px solid #e3e3e9;
   width: 237px;
   box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
   top: -225px;
   position: absolute;
   height: 210px;
   ${breakpoints.md`
      transform: translateX(0);
      right: 0;
   `}
`;

export const LinkList = styled.ul`
   padding: 0;
   text-align: left;
   list-style: none;
`;

export const LinkAnchor = styled.a`
   font-family: Metropolis;
   text-align: left;
   font-size: 1.6rem;
   line-height: 3.6rem;
   font-weight: 500;
   color: #211e4e;
   :hover {
      color: ${colors.brand};
   }
`;

import styled from 'styled-components';
import { colors } from 'styles/variables';

export const CookiesPopupWrapper = styled.div`
   z-index: 3;
   position: fixed;
   width: 100%;
   bottom: 30px;
   display: flex;
   padding: 0 15px;
`;

export const CookiesParagraph = styled.p`
   background-color: white;
   margin: 0 auto;
   text-align: center;
   padding: 15px 17px;
   border-radius: 8px;
   border: 1px solid #e8ecf9;
   box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
   font-size: 1.6rem;
   a {
      padding-bottom: 2px;
      font-weight: 500;
      border-bottom: 1px solid #333;
      &:hover {
         color: ${colors.brand};
         border-bottom: 1px solid ${colors.brand};
      }
   }
`;

export const CookiesButton = styled.button`
   cursor: pointer;
   font-size: 1.6rem;
   font-family: inherit;
   font-weight: 500;
   border: none;
   text-transform: underline;
   background: none;
   padding-bottom: 2px;
   border-bottom: 1px solid #333;
   &:hover {
      color: ${colors.brand};
      border-bottom: 1px solid ${colors.brand};
   }
`;

import styled from 'styled-components';
import { colors, transitions, breakpoints } from 'variables';

export const HamburgerContainer = styled.div`
   display: flex;
   align-items: center;
   z-index: 3;
   font-size: 1.2rem;
   cursor: pointer;

   ${breakpoints.lg`
      display: none;
   `}
`;

export const IconContainer = styled.div`
   display: flex;
   flex-direction: column;
   height: 29px;
   width: 28px;
   justify-content: space-evenly;
   margin-left: 15px;
   cursor: pointer;

   div {
      position: relative;
      left: 0;
      width: 100%;
      height: 2px;
      opacity: 1;
      background-color: ${colors.brand};
      transition: opacity ${transitions.ease}, right ${transitions.easeInOut}, transform ${transitions.easeInOut}, left ${transitions.easeInOut};
      transform-origin: 3px center;

      ${({ isMenuOpen }) => isMenuOpen && `
      &:first-of-type {
         transform: rotate(45deg);
      }
      &:nth-of-type(2) {
         left: 20px;
         opacity: 0;
      }
      &:last-of-type {
         transform: rotate(-45deg);
      }
      `}
   }
`;

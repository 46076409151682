import React from 'react';
import Footer from 'components/Layout/Footer';
import Navbar from 'components/Layout/Navbar';
import CookiesPopup from './CookiesPopup';

const Layout = ({ children }) => {
   return (
      <>
         <CookiesPopup />
         <Navbar />
         <main>{children}</main>
         <Footer />
      </>
   );
};

export default Layout;

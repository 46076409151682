import React, { createContext, useContext, useReducer } from 'react';

const navbarReducer = (state, action) => {
   switch (action.type) {
      case 'SHOW_MENU':
         return {
            ...state,
            isMenuVisible: true,
            navbarHeight: action.payload.navbarHeight,
            navbarPosY: action.payload.navbarPosY,
         };
      case 'HIDE_MENU':
         return {
            ...state,
            isMenuVisible: false,
            navbarPosY: action.payload,
         };
      case 'GO_TO_ID':
         return {
            ...state,
            sectionId: action.payload,
         };
      default:
         return state;
   }
};

const initialState = {
   isMenuOpen: false,
   isMenuVisible: false,
   navbarPosY: 0,
   navbarHeight: 0,
   sectionId: '',
};

const NavbarContext = createContext(initialState);

const NavbarContextProvider = ({ children }) => {
   const [
      { isMenuOpen, isMenuVisible, navbarPosY, navbarHeight, sectionId },
      dispatch,
   ] = useReducer(navbarReducer, initialState);

   const goToId = (id) => dispatch({ type: 'GO_TO_ID', payload: id });

   const showMenu = (navbarHeight, navbarPosY) =>
      dispatch({ type: 'SHOW_MENU', payload: { navbarHeight, navbarPosY } });

   const hideMenu = (navbarPosY) =>
      dispatch({ type: 'HIDE_MENU', payload: navbarPosY });

   return (
      <NavbarContext.Provider
         value={{
            isMenuOpen,
            isMenuVisible,
            navbarPosY,
            navbarHeight,
            sectionId,
            goToId,
            showMenu,
            hideMenu,
         }}
      >
         {children}
      </NavbarContext.Provider>
   );
};

export const useNavbarContext = () => {
   const context = useContext(NavbarContext);
   if (context === undefined) {
      throw new Error('useNavbarContext must be used within a CountProvider');
   }
   return context;
};

export default NavbarContextProvider;

import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

const Container = styled.div`
   width: 100%;
   max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '1100px')};
   padding: ${({ fullwidth }) => (fullwidth ? '0' : '0 15px')};
   display: flex;
   margin: 0 auto;
   justify-content: center;
   ${breakpoints.xl`
      padding: 0;
   `}
`;
export default Container;

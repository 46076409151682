import React from 'react';
import scrollTo from 'utilities/scrollTo';
import StyledButton, { StyledButtonLink } from './Button.styled';

const Button = ({
   children,
   small,
   filled,
   link,
   state,
   onClick,
   linkToHash,
}) => {
   if (linkToHash) {
      return (
         <StyledButton
            $small={small}
            $filled={filled}
            onClick={() => scrollTo(linkToHash, 20)}
         >
            {children}
         </StyledButton>
      );
   }
   return (
      <>
         {link ? (
            <StyledButtonLink
               onClick={onClick}
               to={link}
               state={state ? state : null}
               $small={small}
               $filled={filled}
            >
               {children}
            </StyledButtonLink>
         ) : (
            <StyledButton $small={small} $filled={filled} onClick={onClick}>
               {children}
            </StyledButton>
         )}
      </>
   );
};

Button.defaultProps = {
   small: false,
   filled: false,
   link: false,
   state: false,
};

export default Button;

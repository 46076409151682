import { useLocalStorage } from 'hooks/useLocalStorage';
import {
   CookiesButton,
   CookiesParagraph,
   CookiesPopupWrapper,
} from './CookiesPopup.styled';
import React from 'react';
import privacy from 'assets/privacy.pdf';

const CookiesPopup = () => {
   const [accepted, setAccepted] = useLocalStorage(
      'cookies-accepted',
      'not-accepted',
   );
   if (accepted === 'not-accepted') {
      return (
         <CookiesPopupWrapper>
            <CookiesParagraph>
               Ta strona korzysta z &nbsp;
               <a href={privacy} target="_blank" rel="noopener noreferrer">
                  ciasteczek
               </a>
               , aby usprawnić działanie strony.&nbsp;
               <CookiesButton onClick={() => setAccepted('accepted')}>
                  Zgadzam się
               </CookiesButton>
            </CookiesParagraph>
         </CookiesPopupWrapper>
      );
   } else {
      return null;
   }
};

export default CookiesPopup;

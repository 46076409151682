import styled from 'styled-components';
import { colors, breakpoints } from 'variables';

export const TrialContainer = styled.div`
   width: 100%;
   display: flex;
   background-color: ${colors.trialBg};
   padding-left: 20px;
   margin-top: 60px;
   border-radius: 12px;
   flex-direction: column;

   ${breakpoints.md`
      margin-top: 90px;
      padding-left: 80px;
      flex-direction: row;
   `};
`;

export const TrialHeader = styled.h2`
   line-height: 1.5;
   color: ${colors.black};
   margin-bottom: 10px;

   strong {
      font-weight: 700;
   }

   ${breakpoints.lg`
      line-height: 60px;
   `};
`;

export const InformationContainer = styled.div`
   width: 100%;
   align-self: center;
   padding: 20px 0 10px;

   ${breakpoints.lg`
      padding: 120px 0;
   `};
`;
export const Image = styled.picture`
   display: flex;
   max-width: 290px;
   padding-left: 40px;
   align-self: flex-end;
   img {
      align-self: flex-end;
      width: 100%;
      max-width: 290px;
   }
   ${breakpoints.md`
      width: initial;
      max-width: initial;
      height: 100%;
      img {
         width: initial;
         max-width: initial;
         height: 100%;
      }
   `};
`;

export const TrialSection = styled.div``;

import { css } from 'styled-components';
import { colors, breakpoints } from 'variables';

import metropolisRegular from 'assets/fonts/Metropolis-Regular.woff';
import metropolisBold from 'assets/fonts/Metropolis-Bold.woff';
import metropolisMedium from 'assets/fonts/Metropolis-Medium.woff';
import metropolisExtraBold from 'assets/fonts/Metropolis-ExtraBold.woff';
import metropolisSemiBold from 'assets/fonts/Metropolis-SemiBold.woff';
import metropolisLight from 'assets/fonts/Metropolis-Light.woff';
import metropolisExtraLight from 'assets/fonts/Metropolis-ExtraLight.woff';

const typography = css`
   * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }

   @font-face {
      font-family: 'Metropolis';
      src: local('Metropolis Regular'), local('Metropolis-Regular'),
         url(${metropolisRegular}) format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis';
      src: local('Metropolis Bold'), local('Metropolis-Bold'),
         url(${metropolisBold}) format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis';
      src: local('Metropolis Medium'), local('Metropolis-Medium'),
         url(${metropolisMedium}) format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis Extra';
      src: local('Metropolis Extra Bold'), local('Metropolis-ExtraBold'),
         url(${metropolisExtraBold}) format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis Semi';
      src: local('Metropolis Semi Bold'), local('Metropolis-SemiBold'),
         url(${metropolisSemiBold}) format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis';
      src: local('Metropolis Light'), local('Metropolis-Light'),
         url(${metropolisLight}) format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Metropolis';
      src: local('Metropolis ExtraLight'), local('Metropolis-ExtraLight'),
         url(${metropolisExtraLight}) format('woff');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      margin: 0;
      font-weight: 200;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   a,
   p,
   span,
   figcaption {
      color: ${colors.black};
   }

   b,
   strong {
      font-weight: 700;
   }

   h1 {
      font-size: 2.6rem;
   }
   h2 {
      font-size: 2.4rem;
   }
   h3,
   h4 {
      font-size: 2rem;
   }
   h5 {
      font-size: 1.8rem;
   }
   p {
      font-size: 1.5rem;
      line-height: 2.4rem;

      small {
         font-size: 1.4rem;
      }
   }

   ${breakpoints.md`
      h1 { font-size: 4.2rem; }
      h2 { font-size: 3.4rem; }
      h3 { font-size: 2.5rem; }
      h4 { font-size: 2.1rem; }
      p {
         font-size: 1.6rem;

         small { font-size: 1.4rem; }
      }
   `}

   ${breakpoints.lg`
      h1 { font-size: 6rem; }
      h2 { font-size: 4.5rem; }
      h3 { font-size: 3rem; }
      h4 { font-size: 2.2rem; }
      p {
         line-height: 2.6rem;
         font-size: 1.6rem;

         small { font-size: 1.4rem; }
      }
   `}
`;

export default typography;

import { createGlobalStyle } from 'styled-components';
import { colors, transitions } from 'variables';
import normalize from 'styled-normalize';
import typography from './typography';

const GlobalStyles = createGlobalStyle`
   [data-whatinput="mouse"] *:focus {
      outline: none;
   }
   ${normalize}

   * {
      box-sizing: border-box;
   }

   html {
      font-size: 10px;
      font-family: 'Metropolis', sans-serif;
      font-weight: 400;
   }
   strong {
      font-weight: 600;
   }

   body {
      overflow-x: hidden;
   }

   ul {
      list-style: none;
      margin: 0;
      padding: 0;
   }

   a {
      text-decoration: none;
      transition: color ${transitions.easeInOut};
   }

   button { padding: 0; }

   .active-nav-link {
      color: ${colors.brand};
   }

   .pricing-tooltip {
      max-width: 300px;
      padding: 5px 10px !important;
      line-height: 18px;
      font-size: 1.4rem;
      border-radius: 4px !important;

      &.show {
         opacity: 1 !important;
      }
   }

   ${typography}
`;
export default GlobalStyles;

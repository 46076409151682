export default {
   home: '/',
   production: '/dla-firm-produkcyjnych',
   laboratory: '/dla-laboratoriow',
   construction: '/dla-firm-budowlanych',
   functionalities: '/funkcjonalnosci',
   benefits: '/korzysci',
   pricing: '/cennik',
   contact: '/kontakt',
   privacy: '/prywatnosc',
   implementation: '/historia-wdrozenia',
   confirmation: '/potwierdzenie',
   eu: '/inteligentny-rozwoj',
};
